import { useState, useEffect } from 'react'
import { useThrottle } from 'use-throttle'
import { matchSorter } from 'match-sorter'
import { Industry } from '../contexts/industryContext'

const THROTTLE_TIME = 100

const OTHER_INDUSTRY = 'Other'

const minRanking = {
  key: 'industryLabel',
}

export const matchSorterConfig = {
  keys: ['industryLabel', minRanking],
}

export const useSearch = (term = '', industries: Industry[], maxResultCount: number): Industry[] | null => {
  const [results, setResults] = useState<Industry[]>([])
  const throttledTerm = useThrottle(term, THROTTLE_TIME)

  useEffect(() => {
    const data = matchSorter(industries || [], throttledTerm, matchSorterConfig).slice(0, maxResultCount)

    setResults(
      data.length > 0
        ? data
        : industries.filter(industry => industry.industryLabel === OTHER_INDUSTRY)
    )
  }, [industries, throttledTerm, maxResultCount])

  return throttledTerm.trim() === '' ? null : results
}
