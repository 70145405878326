import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useController } from 'react-hook-form'
import type { Control } from 'react-hook-form'
import { ComboboxInput, ComboboxList, ComboboxPopover, ComboboxOption, Combobox } from '@vp/swan'
import { AccountProfile } from '../models/AccountProfile'
import { Industry, IndustryData, useIndustryContext } from '../contexts/industryContext'
import { useSearch } from '../hooks/useSearch'

interface BusinessIndustryAutoCompleteProps {
  disabled?: boolean
  selectedIndustryData: IndustryData | null
  placeholderText: string
  fieldName: 'businessIndustry'
  control: Control<AccountProfile>
}

const getIndustryMaps = (allIndustries: Industry[]): [Record<string, Industry>, Industry[]] => {
  const labelToIndustryMap: Record<string, Industry> = {}
  const idToIndustryMap: Record<string, Industry> = {}

  for (const industry of allIndustries) {
    labelToIndustryMap[industry.industryLabel] = industry
    idToIndustryMap[industry.industryId] = industry
  }

  const uniqueIndustries = Object.values(labelToIndustryMap)

  return [idToIndustryMap, uniqueIndustries]
}

const getIndustryLabelById = (idToIndustryMap: Record<string, Industry>, industryData: IndustryData | null): string | null => {
  if (!industryData) return null

  const industryId = industryData.industryL3Id ?? industryData.industryL2Id ?? industryData.industryL1Id

  if (!industryId) return null

  return idToIndustryMap[industryId]?.industryLabel ?? null
}

const getIndustryById = (idToIndustryMap: Record<string, Industry>, industryId: string): Industry | undefined => {
  return idToIndustryMap[industryId]
}

export const BusinessIndustryAutoComplete: React.FC<BusinessIndustryAutoCompleteProps> = ({
  disabled,
  selectedIndustryData,
  placeholderText,
  fieldName,
  control,
}) => {
  const { allIndustries } = useIndustryContext()

  const [idToIndustryMap, uniqueIndustries] = useMemo(() => getIndustryMaps(allIndustries), [allIndustries])

  const [fieldState, setFieldState] = useState<{
    selectedKey: string | null
    inputValue: string
  }>({
    selectedKey: null,
    inputValue: '',
  })

  const { field } = useController({
    control,
    name: fieldName
  })

  useEffect(() => {
    const savedIndustryLabel = getIndustryLabelById(idToIndustryMap, selectedIndustryData) ?? ''
    setFieldState({
      inputValue: savedIndustryLabel || '',
      selectedKey: getMostGranularIndustryLevelId(selectedIndustryData),
    })
  }, [idToIndustryMap, selectedIndustryData])

  const getMostGranularIndustryLevelId = (industryData: IndustryData | null): string | null => {
    if (!industryData) return null
    return industryData.industryL3Id ?? industryData.industryL2Id ?? industryData.industryL1Id ?? null
  }

  const onSelectionChange = useCallback((selectedKey: string | number) => {
    const selectedItem: Industry | undefined = getIndustryById(idToIndustryMap, String(selectedKey))
    setFieldState({
      inputValue: selectedItem?.industryLabel || '',
      selectedKey: selectedItem?.industryId || null,
    })
    field.onChange(selectedItem?.industryData || null)
  }, [])

  const onInputChange = useCallback((value: string) => {
    setFieldState(prevState => ({
      inputValue: value,
      selectedKey: value === '' ? null : prevState.selectedKey,
    }))

    if (value === '') {
      field.onChange(null)
    }
  }, [])

  const NUMBER_OF_VISIBLE_RESULTS = 30

  const filteredOptions = useSearch(fieldState.inputValue.toLocaleLowerCase(), uniqueIndustries, NUMBER_OF_VISIBLE_RESULTS)

  return (
    <Combobox
      onSelectionChange={onSelectionChange}
      onInputChange={onInputChange}
      disableAutoFilter
      selectedKey={fieldState.selectedKey || undefined}
      inputValue={fieldState.inputValue}
      aria-label={placeholderText}
    >
      <ComboboxInput aria-label={placeholderText} disabled={disabled} placeholder={placeholderText} />
      <ComboboxPopover>
        <ComboboxList data-testid='industry-options'>
          {filteredOptions && filteredOptions.length > 0
            ? (
                filteredOptions
                  .filter(o => o.industryId !== null && o.industryLabel)
                  .map(o => <ComboboxOption key={o.industryId}>{o.industryLabel}</ComboboxOption>)
              )
            : null}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  )
}

export default BusinessIndustryAutoComplete
