import { getTracking } from '@vp/tracking'
import { AccountProfile } from '../models/AccountProfile'
import { calculateAccountProfileStep, calculateTotalNumberOfAccountProfileSteps, hasAnyMultiOptionSelected, hasAnyOptionSelected, hasIndustrySelected } from './accountProfileStepsCalculator'
import { DROPDOWN_NOT_SELECTED_VALUE } from './constants'

const ACCOUNT_PROFILE_CATEGORY = 'Account Profile'
const SAVE_ACCOUNT_PROFILE_CLICKED_EVENT_NAME = 'Button Clicked'
const SAVE_ACCOUNT_PROFILE_CLICKED_LABEL = 'Save profile'
const IDENTITY_ADDED_EVENT_NAME = 'Identity Added'
const IDENTITY_UPDATED_EVENT_NAME = 'Identity Updated'
const YES = 'YES'
const NO = 'NO'
const ANSWERS_SEPARATOR = '_'
const QUESTION_SEPARATOR = ':'

const FLY_OUT_CATEGORY = 'Fly-Out'
const FLY_OUT_VIEWED_EVENT_NAME = 'Fly-Out Viewed'
const FLY_OUT_CLICKED_EVENT_NAME = 'Fly-Out Clicked'

const FLY_OUT_CANCEL_CLICKED_LABEL = 'Account Profile Cancel Fly-Out'
const FLY_OUT_CANCEL_CONFIRM_CLICKED_DETAIL = 'Account Profile Cancel Confirm'
const FLY_OUT_CANCEL_DISCARD_CLICKED_DETAIL = 'Account Profile Cancel Discard'

const FLY_OUT_DELETE_CLICKED_LABEL = 'Account Profile Delete Fly-Out'
const FLY_OUT_DELETE_CONFIRM_CLICKED_DETAIL = 'Account Profile Delete Confirm'
const FLY_OUT_DELETE_DISCARD_CLICKED_DETAIL = 'Account Profile Delete Discard'

export const trackCancelAccountProfileClickedEvent = () => {
  const tracking = getTracking()
  if (!tracking) { return }
  tracking.track(
    FLY_OUT_VIEWED_EVENT_NAME,
    {
      category: FLY_OUT_CATEGORY,
      label: FLY_OUT_CANCEL_CLICKED_LABEL,
    },
    {}
  )
}

export const trackCancelAccountProfileConfirmClickedEvent = () => {
  const tracking = getTracking()
  if (!tracking) { return }
  tracking.track(
    FLY_OUT_CLICKED_EVENT_NAME,
    {
      category: FLY_OUT_CATEGORY,
      label: FLY_OUT_CANCEL_CLICKED_LABEL,
      eventDetail: FLY_OUT_CANCEL_CONFIRM_CLICKED_DETAIL,
    },
    {}
  )
}

export const trackCancelAccountProfileDiscardClickedEvent = () => {
  const tracking = getTracking()
  if (!tracking) { return }
  tracking.track(
    FLY_OUT_CLICKED_EVENT_NAME,
    {
      category: FLY_OUT_CATEGORY,
      label: FLY_OUT_CANCEL_CLICKED_LABEL,
      eventDetail: FLY_OUT_CANCEL_DISCARD_CLICKED_DETAIL,
    },
    {}
  )
}

export const trackDeleteAccountProfileClickedEvent = () => {
  const tracking = getTracking()
  if (!tracking) { return }
  tracking.track(
    FLY_OUT_VIEWED_EVENT_NAME,
    {
      category: FLY_OUT_CATEGORY,
      label: FLY_OUT_DELETE_CLICKED_LABEL,
    },
    {}
  )
}

export const trackDeleteAccountProfileConfirmClickedEvent = () => {
  const tracking = getTracking()
  if (!tracking) { return }
  tracking.track(
    FLY_OUT_CLICKED_EVENT_NAME,
    {
      category: FLY_OUT_CATEGORY,
      label: FLY_OUT_DELETE_CLICKED_LABEL,
      eventDetail: FLY_OUT_DELETE_CONFIRM_CLICKED_DETAIL,
    },
    {}
  )
}

export const trackDeleteAccountProfileDiscardClickedEvent = () => {
  const tracking = getTracking()
  if (!tracking) { return }
  tracking.track(
    FLY_OUT_CLICKED_EVENT_NAME,
    {
      category: FLY_OUT_CATEGORY,
      label: FLY_OUT_DELETE_CLICKED_LABEL,
      eventDetail: FLY_OUT_DELETE_DISCARD_CLICKED_DETAIL,
    },
    {}
  )
}

export const trackSaveAccountProfileClickedEvent = () => {
  const tracking = getTracking()
  if (!tracking) { return }

  tracking.track(
    SAVE_ACCOUNT_PROFILE_CLICKED_EVENT_NAME,
    {
      category: ACCOUNT_PROFILE_CATEGORY,
      label: SAVE_ACCOUNT_PROFILE_CLICKED_LABEL,
    },
    {}
  )
}

export const trackAccountProfileSavedEvent = (params: {
  accountProfile: AccountProfile,
}) => {
  const tracking = getTracking()
  if (!tracking) { return }

  const { accountProfile } = params

  const eventName = accountProfile.isNewAccountProfile ? IDENTITY_ADDED_EVENT_NAME : IDENTITY_UPDATED_EVENT_NAME

  const questionsAnswered = calculateAccountProfileStep(accountProfile)
  const totalNumberOfQuestions = calculateTotalNumberOfAccountProfileSteps(accountProfile)
  const questionsWithAnswers = buildQuestionsWithAnswers(accountProfile)
  const eventDetail = buildEventDetail(questionsWithAnswers)

  tracking.track(
    eventName,
    {
      category: ACCOUNT_PROFILE_CATEGORY,
      label: `${questionsAnswered}${ANSWERS_SEPARATOR}${totalNumberOfQuestions}`,
      eventDetail,
    },
    {}
  )
}

const buildQuestionsWithAnswers = (accountProfile: AccountProfile): { [key:string]: boolean } => {
  const questionsWithAnswers: { [key:string]: boolean } = {}
  questionsWithAnswers['bUse'] = hasAnyOptionSelected(accountProfile.businessUse)
  questionsWithAnswers['bName'] = accountProfile.businessName !== ''
  questionsWithAnswers['bIndustry'] = hasIndustrySelected(accountProfile.businessIndustry)
  questionsWithAnswers['bSize'] = hasAnyOptionSelected(accountProfile.businessSize)
  questionsWithAnswers['bYear'] = accountProfile.businessLaunchedYear !== DROPDOWN_NOT_SELECTED_VALUE
  questionsWithAnswers['bNotLaunched'] = accountProfile.businessNotLaunchedYet === true
  questionsWithAnswers['bStage'] = hasAnyOptionSelected(accountProfile.businessStage)
  questionsWithAnswers['bYouOwn'] = hasAnyMultiOptionSelected(accountProfile.businessYouOwnOptionsSelected)
  questionsWithAnswers['bYouUseFor'] = hasAnyMultiOptionSelected(accountProfile.businessYouUseForOptionsSelected)
  questionsWithAnswers['bHowYouDesign'] = hasAnyMultiOptionSelected(accountProfile.businessHowYouDesignOptionsSelected)
  questionsWithAnswers['pUse'] = hasAnyOptionSelected(accountProfile.personalUse)
  questionsWithAnswers['pReason'] = hasAnyMultiOptionSelected(accountProfile.personalReasonForShoppingOptionsSelected)
  questionsWithAnswers['pWho'] = hasAnyMultiOptionSelected(accountProfile.personalWhoYouShopForOptionsSelected)
  return questionsWithAnswers
}

const buildEventDetail = (questionsWithAnswers: { [key:string]: boolean }): string => {
  return Object.keys(questionsWithAnswers).map((questionId) => {
    const response = questionsWithAnswers[questionId] ? YES : NO
    return `${questionId}${QUESTION_SEPARATOR}${response}`
  }).join(ANSWERS_SEPARATOR)
}
