import {
  H3,
  FormField,
  FormInputGroup,
  FormLabel,
  StandardForm,
  Dropdown,
  DropdownOption,
  TextInput,
  FieldSet,
  DropdownFloatingLabel,
  DropdownWithFloatingLabel,
  Checkbox,
  Typography,
  Box,
  FormError,
} from '@vp/swan'
import { useLocalization } from '../hooks/useLocalizations'
import MultiOptionSelectFormField from './MultiOptionsSelectFormField'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AccountProfile, cloneAccountProfile } from '../models/AccountProfile'
import { useAccountProfileFormData } from '../hooks/useAccountProfileFormData'
import { useAccountProfile } from '../hooks/useAccountProfile'
import { useDeleteAccountProfile } from '../hooks/useDeleteAccountProfile'
import { useSaveAccountProfile } from '../hooks/useSaveAccountProfile'
import { AccountProfileFormButtons } from './AccountProfileFormButtons'
import { AccountProfileProgressBar } from './AccountProfileProgressBar'
import { AccountProfileAlertBoxes } from './AccountProfileAlertBoxes'
import { calculateAccountProfileStep, calculateTotalNumberOfAccountProfileSteps } from '../utils/accountProfileStepsCalculator'
import { AccountProfileErrorPage } from './AccountProfileErrorPage'
import { useLogger } from '@vp/ubik-context'
import { AccountProfileLoading } from './AccountProfileLoading'
import { DROPDOWN_NOT_SELECTED_VALUE, INITIAL_YEAR, NO_BUSINESS_OPTION_VALUE } from '../utils/constants'
import BusinessIndustryAutoComplete from './BusinessIndustryAutoComplete'
import { IndustryData } from '../contexts/industryContext'
import { trackSaveAccountProfileClickedEvent, trackAccountProfileSavedEvent } from '../utils/tracking'

const BUSINESS_NAME_MAX_LENGTH = 200
export interface AccountProfileFormProps {
  canonicalId: string,
  authorizationHeader: string,
  locale: string,
}

export const AccountProfileForm: React.FC<AccountProfileFormProps> = (props) => {
  const [accountProfile, setAccountProfile] = useState<AccountProfile>()
  const [initialAccountProfile, setInitialAccountProfile] = useState<AccountProfile>()
  const [showSaveAlertBox, setShowSaveAlertBox] = useState(false)
  const [showDeleteAlertBox, setShowDeleteAlertBox] = useState(false)
  const [showInvitationAlertBox, setShowInvitationAlertBox] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const {
    locale,
    canonicalId,
    authorizationHeader,
  } = props

  const {
    control,
    register,
    reset,
    getValues,
    watch,
    formState: { isDirty, errors }
  } = useForm<AccountProfile>({
    mode: 'all',
    defaultValues: { ...accountProfile },
  })

  const { t } = useLocalization()
  const logger = useLogger()

  const { accountProfileFormData, isLoadingFormData, isErrorLoadingFormData } = useAccountProfileFormData(locale)
  const { loadedAccountProfile, isLoadingAccountProfile, isErrorLoadingAccountProfile } =
    useAccountProfile({ canonicalId, authorizationHeader, accountProfileFormData })
  const { deleteAccountProfile, isDeletingAccountProfile, isErrorDeletingAccountProfile, isSuccessDeletingAccountProfile } =
    useDeleteAccountProfile({ canonicalId, authorizationHeader, accountProfileFormData })
  const { saveAccountProfile, isSavingAccountProfile, isErrorSavingAccountProfile, isSuccessSavingAccountProfile } =
    useSaveAccountProfile({ canonicalId, authorizationHeader })

  useEffect(() => {
    if (loadedAccountProfile) {
      const initialAccountProfileStep = calculateAccountProfileStep(loadedAccountProfile)
      const initialTotalNumberOfSteps = calculateTotalNumberOfAccountProfileSteps(loadedAccountProfile)
      setShowInvitationAlertBox(initialAccountProfileStep < initialTotalNumberOfSteps)
      updateAccountProfile(loadedAccountProfile)
    }
  }, [loadedAccountProfile])

  useEffect(() => {
    if (accountProfile) {
      const currentStep = calculateAccountProfileStep(accountProfile)
      setCurrentStep(currentStep)
    }
  }, [accountProfile])

  const handleOnSaveClick = async () => {
    hideActionsAlertBoxes()
    const newAccountProfile = getValues()
    trackSaveAccountProfileClickedEvent()

    try {
      if (newAccountProfile?.businessNotLaunchedYet) {
        newAccountProfile.businessLaunchedYear = DROPDOWN_NOT_SELECTED_VALUE
      }
      if (newAccountProfile?.businessUse === NO_BUSINESS_OPTION_VALUE) {
        newAccountProfile.businessName = ''
        newAccountProfile.businessIndustry = {} as IndustryData
        newAccountProfile.businessSize = DROPDOWN_NOT_SELECTED_VALUE
        newAccountProfile.businessLaunchedYear = DROPDOWN_NOT_SELECTED_VALUE
        newAccountProfile.businessStage = DROPDOWN_NOT_SELECTED_VALUE
        newAccountProfile.businessNotLaunchedYet = false
        newAccountProfile.businessYouOwnOptionsSelected = {}
        newAccountProfile.businessYouUseForOptionsSelected = {}
        newAccountProfile.businessHowYouDesignOptionsSelected = {}
      }
      await saveAccountProfile(newAccountProfile)
      trackAccountProfileSavedEvent({ accountProfile: newAccountProfile })

      if (newAccountProfile) {
        newAccountProfile.isNewAccountProfile = false
      }
      updateAccountProfile(newAccountProfile)
      setShowInvitationAlertBox(true)
    } catch (error) {
      logger.error('Error saving account profile', canonicalId, error)
    } finally {
      setShowSaveAlertBox(true)
      scrollToTheTop()
    }
  }

  const handleOnCancelClick = async () => {
    updateAccountProfile(initialAccountProfile)
    hideActionsAlertBoxes()
    scrollToTheTop()
  }

  const handleOnDeleteProfileClick = async () => {
    if (accountProfileFormData) {
      hideActionsAlertBoxes()
      try {
        const newAccountProfile = await deleteAccountProfile()
        updateAccountProfile(newAccountProfile)
        setShowInvitationAlertBox(true)
      } catch (error) {
        logger.error('Error deleting account profile data', canonicalId, error)
      } finally {
        setShowDeleteAlertBox(true)
        scrollToTheTop()
      }
    }
  }

  const handleOnDismissInvitationAlertBox = async () => {
    setShowInvitationAlertBox(false)
  }

  const updateAccountProfile = (newAccountProfile?: AccountProfile) => {
    if (newAccountProfile) {
      setAccountProfile(newAccountProfile)
      reset(newAccountProfile)
      const initialProfile: AccountProfile = cloneAccountProfile(newAccountProfile)
      setInitialAccountProfile(initialProfile)
    }
  }

  const hideActionsAlertBoxes = () => {
    setShowSaveAlertBox(false)
    setShowDeleteAlertBox(false)
  }

  const scrollToTheTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const isLoading = isLoadingFormData || isLoadingAccountProfile

  const totalNumberOfSteps = calculateTotalNumberOfAccountProfileSteps(accountProfile)
  const isAccountProfileCompleted = currentStep === totalNumberOfSteps

  if (isErrorLoadingFormData || isErrorLoadingAccountProfile) {
    return <AccountProfileErrorPage />
  }

  return (
    <>
      {isLoading && <AccountProfileLoading />}
      <Box data-testid='account-profile-page-content'>
        {!isLoading && (
          <>
            <AccountProfileAlertBoxes
              showSaveAlertBox={showSaveAlertBox}
              isSuccessSavingAccountProfile={isSuccessSavingAccountProfile}
              isErrorSavingAccountProfile={isErrorSavingAccountProfile}
              showDeleteAlertBox={showDeleteAlertBox}
              isSuccessDeletingAccountProfile={isSuccessDeletingAccountProfile}
              isErrorDeletingAccountProfile={isErrorDeletingAccountProfile}
              showInvitationAlertBox={showInvitationAlertBox}
              isAccountProfileCompleted={isAccountProfileCompleted}
              onInvitationAlertBoxClicked={handleOnDismissInvitationAlertBox}
            />

            <AccountProfileProgressBar
              currentStep={currentStep}
              numberOfSteps={totalNumberOfSteps}
            />

            <StandardForm
              mt='between-subsections'
              mb='between-sections'
            >
              <FieldSet>
                <H3 data-testid='account-profile-business-title'>
                  {t('businessTitle')}
                </H3>

                <FormField data-testid='account-profile-business-use'>
                  <FormLabel>
                    <Typography>{t('businessUse')}</Typography>
                  </FormLabel>
                  <FormInputGroup>
                    <Dropdown
                      fullWidth
                      {...register('businessUse')}
                    >
                      <DropdownOption value={DROPDOWN_NOT_SELECTED_VALUE}>{t('select')}</DropdownOption>
                      {accountProfileFormData?.businessUseOptions?.map(option => (
                        <DropdownOption key={option.value} value={option.value}>{t(option.label)}</DropdownOption>
                      ))}
                    </Dropdown>
                  </FormInputGroup>
                </FormField>

                <FormField data-testid='account-profile-business-name'>
                  <FormLabel>
                    {t('businessName')}
                  </FormLabel>
                  <FormInputGroup>
                    <TextInput
                      disabled={watch('businessUse') === NO_BUSINESS_OPTION_VALUE}
                      placeholder={t('businessNamePlaceholder')}
                      {...register('businessName', { maxLength: BUSINESS_NAME_MAX_LENGTH })}
                    />
                    {errors.businessName && <FormError data-testid='account-profile-business-name-error'>{t('businessNameMaxLength')}</FormError>}
                  </FormInputGroup>
                </FormField>

                <FormField data-testid='account-profile-business-industry'>
                  <FormLabel>
                    {t('businessIndustry')}
                  </FormLabel>
                  <FormInputGroup>
                    <BusinessIndustryAutoComplete
                      disabled={watch('businessUse') === NO_BUSINESS_OPTION_VALUE}
                      placeholderText={t('businessIndustryPlaceholder')}
                      fieldName='businessIndustry'
                      selectedIndustryData={accountProfile?.businessIndustry as IndustryData ?? null}
                      control={control}
                    />
                  </FormInputGroup>
                </FormField>

                <FormField data-testid='account-profile-business-size'>
                  <FormLabel>
                    {t('businessSize')}
                  </FormLabel>
                  <FormInputGroup>
                    <Dropdown
                      disabled={watch('businessUse') === NO_BUSINESS_OPTION_VALUE}
                      fullWidth
                      {...register('businessSize')}
                    >
                      <DropdownOption value={DROPDOWN_NOT_SELECTED_VALUE}>{t('select')}</DropdownOption>
                      {accountProfileFormData?.businessSizeOptions?.map(option => (
                        <DropdownOption key={option.value} value={option.value}>{t(option.label)}</DropdownOption>
                      ))}
                    </Dropdown>
                  </FormInputGroup>
                </FormField>

                <FormField data-testid='account-profile-business-launched-year'>
                  <FormLabel>
                    {t('businessLaunchedDate')}
                  </FormLabel>
                  <FormInputGroup mb='4'>
                    <DropdownWithFloatingLabel fullWidth>
                      <Dropdown
                        disabled={watch('businessNotLaunchedYet') || watch('businessUse') === NO_BUSINESS_OPTION_VALUE}
                        {...register('businessLaunchedYear')}
                      >
                        <DropdownOption value={DROPDOWN_NOT_SELECTED_VALUE}>{t('select')}</DropdownOption>
                        {accountProfileFormData?.businessLaunchedYearOptions?.map(option => (
                          <DropdownOption key={option.value} value={option.value}>{option.label}</DropdownOption>
                        ))}
                        <DropdownOption value={INITIAL_YEAR - 1}>{t('earlier')}</DropdownOption>
                      </Dropdown>
                      <DropdownFloatingLabel>{t('businessLaunchedYearFloatingLabel')}</DropdownFloatingLabel>
                    </DropdownWithFloatingLabel>
                  </FormInputGroup>
                </FormField>

                <FormField data-testid='account-profile-business-not-launched-yet'>
                  <FormInputGroup>
                    <label>
                      <Checkbox
                        disabled={watch('businessUse') === NO_BUSINESS_OPTION_VALUE}
                        mr='4'
                        {...register('businessNotLaunchedYet')}
                      />
                      {t('businessNotLaunchedYet')}
                    </label>
                  </FormInputGroup>
                </FormField>

                <FormField data-testid='account-profile-business-stage'>
                  <FormLabel>
                    {t('businessStage')}
                  </FormLabel>
                  <FormInputGroup>
                    <Dropdown
                      disabled={watch('businessUse') === NO_BUSINESS_OPTION_VALUE}
                      fullWidth
                      {...register('businessStage')}
                    >
                      <DropdownOption value={DROPDOWN_NOT_SELECTED_VALUE}>{t('select')}</DropdownOption>
                      {accountProfileFormData?.businessStageOptions?.map(option => (
                        <DropdownOption key={option.value} value={option.value}>{t(option.label)}</DropdownOption>
                      ))}
                    </Dropdown>
                  </FormInputGroup>
                </FormField>

                <MultiOptionSelectFormField
                  disabled={watch('businessUse') === NO_BUSINESS_OPTION_VALUE}
                  dataTestId='account-profile-business-type'
                  formLabel={t('businessType')}
                  control={control}
                  options={accountProfileFormData?.businessYouOwnOptions ?? {}}
                  selectedOptions={accountProfile?.businessYouOwnOptionsSelected ?? {}}
                  fieldName='businessYouOwnOptionsSelected'
                />

                <MultiOptionSelectFormField
                  disabled={watch('businessUse') === NO_BUSINESS_OPTION_VALUE}
                  dataTestId='account-profile-business-you-use-for'
                  formLabel={t('businessYouUseFor')}
                  control={control}
                  options={accountProfileFormData?.businessYouUseForOptions ?? {}}
                  selectedOptions={accountProfile?.businessYouUseForOptionsSelected ?? {}}
                  fieldName='businessYouUseForOptionsSelected'
                />

                <MultiOptionSelectFormField
                  disabled={watch('businessUse') === NO_BUSINESS_OPTION_VALUE}
                  dataTestId='account-profile-business-how-you-design'
                  formLabel={t('businessHowYouDesign')}
                  control={control}
                  options={accountProfileFormData?.businessHowYouDesignOptions ?? {}}
                  selectedOptions={accountProfile?.businessHowYouDesignOptionsSelected ?? {}}
                  fieldName='businessHowYouDesignOptionsSelected'
                />

                <H3 data-testid='account-profile-personal-title' mt='between-subsections'>
                  {t('personalTitle')}
                </H3>

                <FormField data-testid='account-profile-personal-use'>
                  <FormLabel>
                    {t('personalUse')}
                  </FormLabel>
                  <FormInputGroup>
                    <Dropdown
                      fullWidth
                      {...register('personalUse')}
                    >
                      <DropdownOption value={DROPDOWN_NOT_SELECTED_VALUE}>{t('select')}</DropdownOption>
                      {accountProfileFormData?.personalUseOptions?.map(option => (
                        <DropdownOption key={option.value} value={option.value}>{t(option.label)}</DropdownOption>
                      ))}
                    </Dropdown>
                  </FormInputGroup>
                </FormField>

                <MultiOptionSelectFormField
                  dataTestId='account-profile-personal-reason-for-shopping'
                  formLabel={t('personalReasonForShopping')}
                  control={control}
                  options={accountProfileFormData?.personalReasonOptions ?? {}}
                  selectedOptions={accountProfile?.personalReasonForShoppingOptionsSelected ?? {}}
                  fieldName='personalReasonForShoppingOptionsSelected'
                />

                <MultiOptionSelectFormField
                  dataTestId='account-profile-personal-who-you-shop-for'
                  formLabel={t('personalWhoYouShopFor')}
                  control={control}
                  options={accountProfileFormData?.personalWhoOptions ?? {}}
                  selectedOptions={accountProfile?.personalWhoYouShopForOptionsSelected ?? {}}
                  fieldName='personalWhoYouShopForOptionsSelected'
                />

              </FieldSet>

              <AccountProfileFormButtons
                hasErrors={Object.keys(errors).length > 0}
                isFormDirty={isDirty}
                isCanceling={false}
                isSaving={isSavingAccountProfile}
                isDeleting={isDeletingAccountProfile}
                onSaveClick={handleOnSaveClick}
                onCancelClick={handleOnCancelClick}
                onDeleteProfileClick={handleOnDeleteProfileClick}
              />

            </StandardForm>
          </>
        )}
      </Box>
    </>

  )
}

export default AccountProfileForm
